import type { SearchResultSet, SearchSort } from './types'
import type { SearchContext } from './SearchProvider'

export const mockSearchResultSet: SearchResultSet = {
  error: null,
  results: [
    {
      id: 'sample-id',
      name: 'Super cool technology!',
      slug: '/super-cool',
      metafields: {},
    },
    {
      id: 'sample-id-1',
      name: 'Super cool product!',
      slug: '/super-cool-thing',
      metafields: {},
    },
  ],
  searchValue: 'show me something cool',
  totalResults: 2,
}

const mockEmptySearchResultSet: SearchResultSet = {
  error: null,
  results: [],
  searchValue: 'no match term',
  totalResults: 0,
}

const mockErrorSearchResultSet: SearchResultSet = {
  error: new Error('The ES search server has stopped responding.'),
  results: [],
  searchValue: 'show me something cool',
  totalResults: 0,
}

const mockSearchSort: SearchSort = {
  name: 'Mock',
  field: 'mock',
  order: 'asc',
}

export const mockedContext: SearchContext = {
  currentPage: 1,
  currentResultLoading: false,
  currentResultSet: mockSearchResultSet,
  currentSearchValue: 'show me something cool',
  currentFilters: {},
  setCurrentFilters: () => {},
  currentSort: mockSearchSort,
  setCurrentSort: () => {},
  previewResultLoading: false,
  previewResultSet: mockSearchResultSet,
  searchForTerm: () => {},
  previewSearchValue: 'show me something cool',
  setPreviewSearchValue: () => {},
}

export const mockedDefaultContext: SearchContext = {
  currentPage: 1,
  currentResultLoading: false,
  currentResultSet: null,
  currentSearchValue: '',
  currentFilters: {},
  setCurrentFilters: () => {},
  currentSort: mockSearchSort,
  setCurrentSort: () => {},
  previewResultLoading: false,
  previewResultSet: null,
  searchForTerm: () => {},
  previewSearchValue: '',
  setPreviewSearchValue: () => {},
}

export const mockedEmptyResultsContext: SearchContext = {
  currentPage: 1,
  currentResultLoading: false,
  currentResultSet: mockEmptySearchResultSet,
  currentSearchValue: 'no match term',
  currentFilters: {},
  setCurrentFilters: () => {},
  currentSort: mockSearchSort,
  setCurrentSort: () => {},
  previewResultLoading: false,
  previewResultSet: mockEmptySearchResultSet,
  searchForTerm: () => {},
  previewSearchValue: 'no match term',
  setPreviewSearchValue: () => {},
}

export const mockedErrorResultsContext: SearchContext = {
  currentPage: 1,
  currentResultLoading: false,
  currentResultSet: mockErrorSearchResultSet,
  currentSearchValue: 'show me something cool',
  currentFilters: {},
  setCurrentFilters: () => {},
  currentSort: mockSearchSort,
  setCurrentSort: () => {},
  previewResultLoading: false,
  previewResultSet: mockErrorSearchResultSet,
  searchForTerm: () => {},
  previewSearchValue: 'show me something cool',
  setPreviewSearchValue: () => {},
}

export const mockedLoadingContext: SearchContext = {
  currentPage: 1,
  currentResultLoading: true,
  currentResultSet: null,
  currentSearchValue: '',
  currentFilters: {},
  setCurrentFilters: () => {},
  currentSort: mockSearchSort,
  setCurrentSort: () => {},
  previewResultLoading: true,
  previewResultSet: null,
  searchForTerm: () => {},
  previewSearchValue: 'show me something cool',
  setPreviewSearchValue: () => {},
}

export const mockedPreviewContext: SearchContext = {
  currentPage: 1,
  currentResultLoading: false,
  currentResultSet: null,
  currentSearchValue: '',
  currentFilters: {},
  setCurrentFilters: () => {},
  currentSort: mockSearchSort,
  setCurrentSort: () => {},
  previewResultLoading: false,
  previewResultSet: mockSearchResultSet,
  searchForTerm: () => {},
  previewSearchValue: 'show me something cool',
  setPreviewSearchValue: () => {},
}
