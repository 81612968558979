/* eslint-disable no-console */
// Centralize logging through one class so it can be turned off in some environments

class Logger {
  log = console.log.bind(window.console)

  error = console.error.bind(window.console)

  debug = process.env.REACT_APP_DEBUG === 'true' ? console.log.bind(window.console) : (_: string) => {}
}

const logger = new Logger()
export default logger
