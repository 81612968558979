// @flow strict
import React from 'react'

import { FlexBox } from 'components'

import {
  AboutSBIR,
  Policies,
  SiteLinks,
  SocialLinks,
} from './components'

const Footer = () => (
  <div className="w-full bg-dark-blue-grey z-20">
    <SocialLinks />
    <FlexBox className="w-full flex-wrap border border-white border-opacity-1">
      <SiteLinks />
      <AboutSBIR />
    </FlexBox>
    <Policies />
  </div>
)

export default Footer
