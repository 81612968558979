// Creates and configures an OIDC user manager
import { Log as OidcLogger, UserManager } from 'oidc-client'

const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID || ''
const clientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET || ''
const logoutRedirectUri = process.env.REACT_APP_OAUTH_LOGOUT_REDIRECT_URI || ''
const redirectUri = process.env.REACT_APP_OAUTH_REDIRECT_URI || ''
const oauthUrl = process.env.REACT_APP_OAUTH_URL || ''

const userManager = new UserManager({
  authority: oauthUrl,
  client_id: clientId,
  client_secret: clientSecret,
  post_logout_redirect_uri: logoutRedirectUri,
  redirect_uri: redirectUri,
  response_type: 'code',
  scope: 'openid',
})

OidcLogger.logger = console
OidcLogger.level = OidcLogger.INFO

export default userManager
