// @flow strict
import React, { useState } from 'react'

const ProductHeaderAbstract = ({ description }: { description: ?string}) => {
  const [showing, setShowing] = useState(false)

  const toggleShowing = (e) => {
    e.preventDefault()

    setShowing(!showing)
  }

  const fadeStyles = {
    background: 'linear-gradient(to bottom, rgba(255, 255, 255,0) 0%,rgba(255, 255, 255,1) 75%)',
  }

  return (
    <div className="relative leading-8">
      <div className={`${showing ? 'max-h-200 pb-8' : 'max-h-24'} overflow-hidden z-0 transition-height duration-500 ease-linear`}>
        {description}
      </div>
      <div
        className={`${showing ? 'hidden' : ''} h-24 -mt-24 z-10 absolute w-full`}
        style={fadeStyles}
      />
      <button
        tabIndex={0}
        role="radio"
        type="button"
        aria-checked={showing}
        className={`${showing ? '' : '-mt-8'} cursor-pointer absolute z-20 text-blue-600 uppercase`}
        onClick={toggleShowing}
        onKeyDown={toggleShowing}
      >
        { showing ? 'Read Less' : 'Read More'}
      </button>
      <div />
    </div>
  )
}

export default ProductHeaderAbstract
