// @flow strict
import React from 'react'

type VendorSidebarProps = {
  companyName: ?string,
}

const VendorSidebar = ({
  companyName,
}: VendorSidebarProps) => (
  <>
    <div className="bg-grey-100 p-8 rounded">
      <h3 className="font-bold text-dark-grey text-lg">
        Have a question for
        {' '}
        {companyName}
        ?
      </h3>

      <button type="button" className="mt-10 border border-solid border-bluish rounded py-2 w-full font-semibold text-bluish uppercase">Contact</button>
    </div>
  </>
)

export default VendorSidebar
