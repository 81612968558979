// @flow strict
import React from 'react'

import { SearchInput } from 'search'

const SearchBox = () => (
  <div className="w-112 xl:w-134 h-112 bg-white rounded" style={{ boxShadow: '0 2px 16px 3px rgba(0, 0, 0, 0.09)' }}>
    <div className="pt-11 px-6 font-sans font-bold text-center text-2xl text-dark-grey">
      SEARCH FROM THOUSANDS OF AVAILABLE TECHNOLOGIES
    </div>
    <div className="h-10" />
    <div className="px-6">
      <SearchInput />
    </div>
    <div className="pt-7 px-6 font-title font text-dusk-blue text-4xl">
      <div className="flex items-center">
        1
        <p className="pl-5 font-semibold text-xl">
          Search for SBIR Funded Technology
        </p>
      </div>
    </div>
    <div className="pt-1 px-6 font-title text-dusk-blue text-4xl">
      <div className="flex items-center">
        2
        <p className="pl-5 font-semibold text-xl">
          Notify A Contracting Officer
        </p>
      </div>
    </div>
    <div className="pt-1 px-6 font-title text-dusk-blue text-4xl">
      <div className="flex items-center">
        3
        <p className="pt-2 pl-5 font-semibold text-xl">
          Complete the Mission
        </p>
      </div>
    </div>
  </div>
)

export default SearchBox
