import React, { useEffect, useState } from 'react'

import client from 'utils/elasticsearch'

import Category from './Category'

const Categories = () => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    async function getCategories() {
      const result = await client.search({
        index: 'sbir-catalog',
        body: {
          size: 0,
          aggs: {
            categories: {
              terms: {
                field: 'categories.raw',
                size: 100,
              },
            },
          },
        },
      })

      const {
        aggregations: {
          categories: {
            buckets,
          },
        },
      } = result

      // eslint-disable-next-line camelcase
      setCategories(buckets.map(({ key, doc_count }) => ({ name: key, count: doc_count })))
    }

    getCategories()
  }, [])

  return (
    <div className="bg-light-grey flex justify-center flex-col py-64">
      <h2 className="px-8 mb-4 font-title text-dusk-blue text-2xl lg:text-4xl text-center flex-1">Browse by Technology Area</h2>
      <p className="w-1/2 self-center mb-6 leading-6 text-gray-500 text-center flex-1">Over 5,000 SBIR contracts are available to search through our robust catalog. Since the 1980&apos;s the SBIR program has reduced barriers, accelerated process, and seeded the future of the U.S. Air Force through innovation and forward-thinking technology.</p>
      <div className="flex flex-row flex-wrap justify-center px-32">
        {categories.map(({ name, count }) => (
          <Category name={name} count={count} key={name} />
        ))}
      </div>
    </div>
  )
}

export default Categories
