// @flow strict
import React from 'react'
import numeral from 'numeral'

type ProductDetailsProps = {
  contractNumber: ?string,
  duns: ?string,
  hubzoneOwned: ?string,
  institution: ?string,
  rdCost: ?string,
  sedStatus: ?string,
  solicitCode: ?string,
  solicitNumber: ?string,
  solicitYear: ?string,
  trackingNumber: ?string,
  womanOwned: ?boolean,
}

const ProductDetails = ({
  contractNumber,
  duns,
  hubzoneOwned,
  institution,
  rdCost,
  sedStatus,
  solicitCode,
  solicitNumber,
  solicitYear,
  trackingNumber,
  womanOwned,
}: ProductDetailsProps) => (
  <>
    <div className="font-bold uppercase text-gray-800 my-4">Additional Details</div>
    <div className="flex flex-row">
      <div className="w-1/3">
        <p className="my-2 text-gray-700">
          Proposal Number:
          <span />
        </p>
        <p className="my-2 text-gray-700">
          Contract Number:
          <span className="text-gray-800 font-bold ml-2">{contractNumber}</span>
        </p>
        <p className="my-2 text-gray-700">
          Solicitation topic code:
          <span className="text-gray-800 font-bold ml-2">{solicitCode}</span>
        </p>
        <p className="my-2 text-gray-700">
          Solicitation number:
          <span className="text-gray-800 font-bold ml-2">{solicitNumber}</span>
        </p>
        <p className="my-2 text-gray-700">
          Solicitation year:
          <span className="text-gray-800 font-bold ml-2">{solicitYear}</span>
        </p>
        <p className="my-2 text-gray-700">
          Agency tracking number:
          <span className="text-gray-800 font-bold ml-2">{trackingNumber}</span>
        </p>
      </div>
      <div className="w-1/3">
        <p className="my-2 text-gray-700">
          DUNS number:
          <span className="text-gray-800 font-bold ml-2">{duns}</span>
        </p>
        <p className="my-2 text-gray-700">
          Research institution:
          <span className="text-gray-800 font-bold ml-2">{institution || 'N/A'}</span>
        </p>
        <p className="my-2 text-gray-700">
          Woman-Owned:
          <span className="text-gray-800 font-bold ml-2">{womanOwned ? 'Yes' : 'No'}</span>
        </p>
        <p className="my-2 text-gray-700">
          Socially/Economically disadvantaged:
          <span className="text-gray-800 font-bold ml-2">{sedStatus ? 'Yes' : 'No'}</span>
        </p>
        <p className="my-2 text-gray-700">
          Service-disabled veteran-owned:
          <span className="text-gray-800 font-bold ml-2">{hubzoneOwned ? 'Yes' : 'No'}</span>
        </p>
      </div>
      <div className="w-1/3">
        <p className="my-2 text-gray-700">
          R&D costs:
          <span className="text-gray-800 font-bold ml-2">{rdCost ? numeral(parseFloat(rdCost)).format('$0,0.00') : 'N/A'}</span>
        </p>
      </div>
    </div>
  </>
)

export default ProductDetails
