// @flow strict
import React from 'react'

import { desktopBreakpoint } from 'utils/constants'
import { useWindowDimensions } from 'utils'

import SearchBox from './SearchBox'

const Hero = () => {
  const { width } = useWindowDimensions()

  const clipPath = width >= desktopBreakpoint ? 'polygon(0 0, 100% 0%, 100% 72%, 0 100%)' : 'polygon(0 0, 100% 0%, 100% 80%, 0 100%)'
  return (
    <>
      <div
        className="absolute flex w-full h-200 lg:h-150 z-20 bg-dark-blue-grey"
        style={{ clipPath }}
      />
      <div className="absolute flex flex-wrap h-200 lg:h-150 w-full">
        <div className="flex flex-col w-full lg:w-1/2 pl-6 pr-6 lg:pl-18 lg:pr-0 z-20">
          <div className="pt-6 lg:pt-40 font-title text-5xl text-white text-center lg:text-left" style={{ lineHeight: 1.21 }}>
            Find The Technology Needed to Complete Your Mission
          </div>
          <div className="pt-6 font-sans text-xl text-white text-center lg:text-left">
            Search through thousands of SBIRs to help you complete the needs of your mission today.
          </div>
        </div>
        <div className="flex w-full lg:w-1/2 z-20 justify-center items-center">
          <SearchBox />
        </div>
      </div>
    </>
  )
}

export default Hero
