// @flow strict
import React from 'react'

import droneBackground from '../assets/drone.png'

const SecondaryHero = () => (
  <div className="w-full pt-145 lg:pt-25">
    <div className="absolute w-full h-full">
      <div className="px-8 font-title text-dusk-blue text-2xl lg:text-4xl text-center" style={{ paddingTop: '57%' }}>
        {'“There\'s no telling what the future can hold. We have to do our part in acquisition and that means being able to develop war-winning systems at a pace that today\'s technology, trends and threats require.”'}
        <div> - Dr. Will Roper</div>
      </div>
    </div>
    <img
      alt="drone-background"
      className="w-full object-cover"
      src={droneBackground}
    />
  </div>
)

export default SecondaryHero
