// @flow strict
import React from 'react'
import _ from 'lodash'

type PaginationProps = {
  currentPage: number,
  goToPage: (goTo: number) => void,
  totalPages: number,
}

type PaginationButtonProps = {
  active: boolean,
  goToPage: (goTo: number) => void,
  page: number,
}

export const PaginationButton = (props: PaginationButtonProps) => {
  const { active, goToPage, page } = props
  const className = active ? 'h-6 w-6 border border-bluish rounded font-sans text-sm text-center text-bluish'
    : 'h-6 w-6 font-sans text-sm text-center text-black text-opacity-60'
  return (
    <button
      className={className}
      onClick={() => goToPage(page)}
      type="button"
    >
      {page}
    </button>
  )
}

const Pagination = (props: PaginationProps) => {
  const { currentPage, goToPage, totalPages } = props

  let paginationRange
  if (totalPages < 5) {
    paginationRange = _.range(1, totalPages + 1) // small result set case
  } else if (currentPage < 3) {
    paginationRange = _.range(1, 6) // starting case
  } else if (currentPage + 2 > totalPages) {
    paginationRange = _.range(totalPages - 4, totalPages + 1) // ending case
  } else {
    paginationRange = _.range(currentPage - 2, currentPage + 3) // normal range
  }

  return (
    <div className="pt-10 pb-20 pr-20 flex justify-end">
      {
        paginationRange.map((page) => {
          const active = page === currentPage
          return (
            <PaginationButton active={active} goToPage={goToPage} key={page} page={page} />
          )
        })
      }
    </div>
  )
}

export default Pagination
