// Product Detail Container
// @flow strict
import React from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { metafieldValueForKey } from '../product-detail-page/helpers'

import VendorPresenter from './presenter'
import { GET_VENDOR } from './graphql/queries'

const VendorContainer = () => {
  const params = useParams()
  const slug = _.get(params, 'slug')

  const nameInput = slug.replaceAll('_', ',').replaceAll('-', ' ')

  const variables = {
    name: nameInput,
  }

  const { data, error, loading } = useQuery(GET_VENDOR, { variables })

  if (loading) return (<div>Loading...</div>)

  const { companies } = data

  const [vendor] = companies

  const NotFoundMarkup = (
    <div style={{ padding: 40 }}>Not Found</div>
  )

  if (!vendor) return NotFoundMarkup
  console.log(vendor)

  const {
    Address1,
    Address2,
    Business_Contact_Name: businessContactName,
    Business_Contact_Phone: businessContactPhone,
    Business_Contact_Email: businessContactEmail,
    City,
    Company_Phone: companyPhone,
    Company_Website: companyUrl,
    Description: description,
    Hubzone_Owned,
    Images,
    Logo,
    Name: companyName,
    Number_Employees,
    Socially_Economically_Disadvantaged,
    State,
    Start_Year: startYear,
    Woman_Owned,
    Zip,
    sbirs,
  } = vendor

  let businessLogoUrl
  if (Logo) {
    ({ url: businessLogoUrl } = Logo)
  }

  const companyAddress = `
    ${Address1}
    ${Address2}
  `

  // Company metafields
  const sbirObjects = sbirs.map((sbir) => ({
    title: sbir.Title,
    contract: sbir.Contract,
    agency: sbir.Agency,
    branch: sbir.Branch,
    phase: sbir.Phase,
  }))

  return (
    <VendorPresenter
      title={companyName}
      businessContactName={businessContactName}
      businessContactPhone={businessContactPhone}
      businessContactEmail={businessContactEmail}
      businessLogoUrl={businessLogoUrl}
      companyName={companyName}
      companyAddress={companyAddress}
      companyPhone={companyPhone}
      companyUrl={companyUrl}
      description={description}
      products={sbirObjects}
      startYear={startYear}
    />
  )
}

export default VendorContainer
