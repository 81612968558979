import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import App from 'App'
import AuthProvider from 'auth'
import * as serviceWorker from 'serviceWorker'
import { client } from 'config'

import 'tailwind.output.css'

const root = document.getElementById('root')

if (root !== null) {
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Router>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Router>
      </ApolloProvider>
    </React.StrictMode>,
    root,
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
