// @flow strict
import React from 'react'

type ProductHeaderDetailProps = {
  agency: ?string,
  branch: ?string,
  phase: ?string,
  program: ?string,
  tags: string[],
  title: ?string,
}

const ProductHeaderDetails = ({
  agency, branch, phase, program, tags, title,
}: ProductHeaderDetailProps) => (
  <>
    <div>
      <div className="uppercase text-gray-800 font-bold">{ phase }</div>
      <div className="uppercase text-gray-600 text-sm">
        {agency}
        {' '}
        |
        {' '}
        {branch}
        {' '}
        |
        {' '}
        {program}
      </div>
      <div className="text-4xl font-title text-indigo-800 my-4">{ title }</div>
      <div className="flex flex-row flex-wrap">
        {tags.map((tag) => (
          <div className="rounded border-gray-400 border p-2 text-gray-600 mr-2 mb-2" key={tag}>{tag}</div>
        ))}
      </div>
    </div>
  </>
)

export default ProductHeaderDetails
