// @flow strict
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import AwardTimeline from './AwardTimeline'

type ProductSidebarProps = {
  companyName: ?string,
  companyAddress: ?string,
  businessContactName: ?string,
  businessContactPhone: ?string,
  businessContactEmail: ?string,
  principalName: ?string,
  principalPhone: ?string,
  principalEmail: ?string,
  phase: ?string,
  rdCost: ?string,
  awardStart: ?string,
}

const ProductSidebar = ({
  companyName,
  companyAddress,
  businessContactName,
  businessContactPhone,
  businessContactEmail,
  principalName,
  principalPhone,
  principalEmail,
  phase,
  rdCost,
  awardStart,
}: ProductSidebarProps) => (
  <>
    <div className="flex flex-col justify-left mx-8 text-xs text-gray-800">
      <div className="my-2">
        <div className="uppercase font-bold text-sm">{companyName}</div>
        <div className="w-40">{companyAddress}</div>
      </div>

      <div className="my-2">
        <div className="font-bold text-gray-700">Business Contact</div>
        {businessContactName && businessContactName !== 'N/A' && (
          <div>{businessContactName}</div>
        )}
        {businessContactPhone && businessContactPhone !== 'N/A' && (
          <div>{businessContactPhone}</div>
        )}
        { businessContactEmail && businessContactEmail !== 'N/A' && (
          <div className="text-bluish">
            <a href={`mailto:${businessContactEmail}`}>{businessContactEmail}</a>
          </div>
        )}
      </div>

      <div className="my-2">
        <div className="font-bold text-gray-700">Principal Investigator</div>
        {principalName && principalName !== 'N/A' && (
          <div>{principalName}</div>
        )}
        {principalPhone && principalPhone !== 'N/A' && (
          <div>{principalPhone}</div>
        )}
        { principalEmail && principalEmail !== 'N/A' && (
          <div className="text-bluish">
            <a href={`mailto:${principalEmail}`}>{principalEmail}</a>
          </div>
        )}
      </div>

      <div className="uppercase text-bluish">
        <a href="">
          Learn More
          {' '}
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
      </div>

      <hr className="mt-6 mb-2" />

      <div className="my-2">
        <div className="font-bold uppercase">Award Timeline</div>

        <AwardTimeline awardPhase={phase} rdCost={rdCost} awardStart={awardStart} />
      </div>

      <hr className="mt-2" />

    </div>
  </>
)

export default ProductSidebar
