// @flow strict
import React from 'react'

const ProductFeatures = ({ features }: { features: string[]}) => (
  <>
    <div className="uppercase text-gray-800 font-bold mb-2">Features</div>
    <div>
      <ul className="divide-x divide-gray-400 flex flex-row">
        {features.map((feature) => (
          <li className="first:ml-0 first:pl-0 ml-4 pl-4" key={feature}>{feature}</li>
        ))}
      </ul>
    </div>
  </>
)

export default ProductFeatures
