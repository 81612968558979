// @flow strict
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { useAuth } from 'auth'
import { routes } from 'utils'
import { Alert, FlexBox } from 'components'

import logo from 'assets/sbir-logo.png'
import searchIcon from 'assets/search.svg'
import cartIcon from './assets/cart.svg'
import heartIcon from './assets/heart.png'
import userIcon from './assets/account.svg'

const errorTitle = 'Unable to Login'
const linkTextClass = 'font-sans font-semibold text-sm text-charcoal-grey'

type NavLinkProps = {
  content: string,
  route: string,
}

const NavLink = (props: NavLinkProps) => {
  const content = _.get(props, 'content')
  const route = _.get(props, 'route')
  return (
    <div className="flex items-center">
      <Link to={route}>
        <p className={linkTextClass}>{content}</p>
      </Link>
    </div>
  )
}

const Spacing = () => (
  <div className="w-10" />
)

const NavBar = () => {
  const auth = useAuth()

  if (!auth) throw new Error('TopBar must be a child of AuthProvider')

  const authError = _.get(auth, 'authError')
  const isAuthenticated = _.get(auth, 'isAuthenticated')

  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    setShowAlert(!!authError)
  }, [authError])

  const loginButtonPressed = () => {
    isAuthenticated ? auth.logout() : auth.login()
  }

  const errorDescription = authError ? authError.message : ''

  const dismissAlert = () => {
    setShowAlert(false)
  }

  return (
    <div className="w-full h-20 bg-white fixed top-0 z-30 border">
      <FlexBox justifyContent="justify-between">
        <div className="flex">
          <Link to={routes.home} className="flex">
            <img className="h-10 pl-10 pr-10 self-center" src={logo} alt="SBIR logo" />
          </Link>
          <div className="h-20 w-px bg-grey-100" />
          <Spacing />
          <NavLink content="ABOUT" route={routes.about} />
          <Spacing />
          <NavLink content="CONTACT US" route={routes.contact} />
        </div>
        <div className="flex pr-12 right-0">
          <a className="flex pl-4 pr-4" href="https://strapi.defense.market">
            <img src={userIcon} alt="user icon" style={{ height: 17, width: 17 }} />
            <div className="w-2" />
            <p className={linkTextClass}>{isAuthenticated ? 'LOG OUT' : 'LOG IN'}</p>
          </a>
          <div className="h-6 w-px bg-grey-200" />
          <Link to="/products">
            <div className="flex pl-4 pr-4">
              <img src={searchIcon} alt="search icon" style={{ height: 18, width: 18 }} />
            </div>
          </Link>
          {/* <div className="h-6 w-px bg-grey-200" /> */}
          {/* <div className="flex pl-4 pr-4">
            <img src={heartIcon} alt="search icon" style={{ height: 17, width: 17 }} />
          </div> */}
          {/* <div className="h-6 w-px bg-grey-200" /> */}
          {/* <div className="flex pl-4 pr-4">
            <img src={cartIcon} alt="cart icon" style={{ height: 16, width: 16 }} />
          </div> */}
        </div>
      </FlexBox>
      { showAlert ? (
        <Alert description={errorDescription} dismissAlert={dismissAlert} title={errorTitle} />
      ) : null }
    </div>
  )
}

export default NavBar
