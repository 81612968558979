// @flow strict
import client from 'utils/elasticsearch'
import logger from 'utils/logger'

import type {
  SearchResult, SearchResultSet, SearchFilter, SearchSort,
  ElasticSearchFilter, ElasticSearchSort,
} from './types'

const filterSearch = (
  filters: SearchFilter,
): Array<ElasticSearchFilter> => {
  // takes an object with SearchFilter keys
  // values can be string, boolean, or array of strings
  // returns array of elasticsearch filters (terms, range, match)

  if (!filters) {
    return []
  }

  const elasticsearchFilters = []

  Object.keys(filters).forEach((key) => {
    const value = filters[key]
    if (!value) {
      // not set or false, don't send
      return null
    }

    let constraint = {}

    if (value instanceof Array) {
      // if array, set as TERMS
      if (value.length === 0) { return null }
      const terms = {}
      terms[`metafields.${key}`] = value.map((x) => (
        x.replace('Phase ', '')
      ))
      constraint = { terms }
    } else if (key.endsWith('-min')) {
      // if min/max, set as RANGE
      const range = {}
      const shortKey = key.split('-').slice(0, -1).join('-')
      range[`metafields.${shortKey}`] = {
        gte: filters[`${shortKey}-min`],
        lte: filters[`${shortKey}-max`],
      }

      constraint = { range }
    } else if (key.endsWith('-max')) {
      // skip it, we already handled with min
      return null
    } else {
      // else, set as MATCH
      const match = {}

      if (key === 'categories.raw') {
        match['categories.raw'] = value
      } else {
        match[`metafields.${key}`] = value
      }

      constraint = { match }
    }

    elasticsearchFilters.push(constraint)
    return null
  })

  return elasticsearchFilters.filter((x) => x) // remove null values
}

const sortSearch = (
  sort: SearchSort,
): Array<ElasticSearchSort> => {
  // only support a single sorting field and order
  const elasticsearchSort = {}
  elasticsearchSort[sort.field] = sort.order
  return [elasticsearchSort]
}

export const searchRequest = (
  term: string = null,
  filters: SearchFilter = {},
  sort: SearchSort,
  from: number = 0,
  size: number = 50,
): Promise<SearchResultSet> => new Promise < SearchResultSet >((resolve, reject) => {
  const searchObject = {
    from,
    size,
    index: 'sbir-catalog',
    body: {
      query: {
        bool: {
          filter: filterSearch(filters),
        },
      },
      sort: sortSearch(sort),
    },
  }

  if (term) {
    searchObject.body.query.bool.should = [
      { match: { abstract: term } },
      { match: { title: term } },
      { match: { vendor: term } },
    ]
  }

  // if (term) {
  //   searchObject.body.query.multi_match = {
  //     query: term,
  //     fields: ['abstract', 'title', 'vendor'],
  //   }
  // }

  client.search(searchObject)
    .then((result) => {
      if (!result.hits || !result.hits.hits) {
        resolve({
          error: null,
          results: [],
          searchValue: term,
          totalResults: 0,
        })
        return
      }

      const results = result.hits.hits.map((hit) => {
        /* eslint-disable no-underscore-dangle */
        const searchResult: SearchResult = {
          id: hit._source.id,
          name: hit._source.title,
          slug: hit._source.slug,
          metafields: hit._source.metafields,
          vendor: hit._source.vendor,
        }
        return searchResult
        /* eslint-enable no-underscore-dangle */
      })

      const resultTotalCount = result.hits.total.value
      resolve({
        results,
        error: null,
        searchValue: term,
        totalResults: resultTotalCount || 0,
      })
    })
    .catch((error) => {
      logger.error(`ElasticSearch error:\n${error}`)
      reject(error)
    })
})
