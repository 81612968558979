// @flow strict
import React from 'react'
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

import { FlexBox } from 'components'
import logo from 'assets/sbir-logo.png'

import SocialLink from './SocialLink'
import dvidsLogo from '../assets/dvidslogo.png'

const SocialLinks = () => (
  <FlexBox height="h-20 sm:h-26">
    <FlexBox className="w-0 md:w-full border border-white border-opacity-1" justifyContent="justify-between">
      <img className="h-10 pl-6 lg:pl-18" src={logo} alt="SBIR logo" />
    </FlexBox>
    <FlexBox className="w-full" justifyContent="justify-start">
      <SocialLink icon={faFacebookF} link="https://www.facebook.com/afsbirsttr/" />
      <SocialLink icon={faTwitter} link="https://twitter.com/AF_SBIR_STTR/" />
      <SocialLink icon={faLinkedinIn} link="https://www.linkedin.com/company/afsbirsttr" />
      <SocialLink icon={faInstagram} link="https://www.instagram.com/afsbirsttr/" />
      <SocialLink icon={faYoutube} link="https://www.youtube.com/channel/UCb3EQFQNK0pw5EFv0rAkTBg" />
      <FlexBox className="w-32 lg:w-40 h-full flex-1 border border-white border-opacity-1" justifyContent="justify-start">
        <a className="pl-2 sm:pl-8" href="https://www.dvidshub.net/unit/AFSBIRSTTR">
          <img src={dvidsLogo} alt="D Vids logo" />
        </a>
      </FlexBox>
    </FlexBox>
  </FlexBox>
)

export default SocialLinks
