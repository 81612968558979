// @flow strict
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { Redirect, useLocation } from 'react-router-dom'

import logger from 'utils/logger'
import UserManager from './UserManager'

const ACCESS_TOKEN_KEY = 'token'
const ID_TOKEN_KEY = 'id_token'
const REDIRECT_PATH_KEY = 'redirectPath'

export type AuthService = {
  authError: null | Error,
  isAuthenticated: boolean,
  login: () => void,
  logout: () => void,
}

export const AuthContext = React.createContext<?AuthService>()

export const useAuth = () => useContext(AuthContext)

type AuthProviderProps = {
  children?: React.Node,
}

const AuthProvider = (props: AuthProviderProps) => {
  const location = useLocation()

  const [authError, setAuthError] = useState(null)
  const [accessToken, setAccessToken] = useState(localStorage.getItem(ACCESS_TOKEN_KEY))
  const [idToken, setIdToken] = useState(localStorage.getItem(ID_TOKEN_KEY))
  const [redirect, setRedirect] = useState(false)

  // Parse auth token from callback
  useEffect(() => {
    // Handle login case
    if (location.pathname === '/callback') {
      logger.debug('Login callback processing...')
      UserManager.signinRedirectCallback().then((response) => {
        logger.debug('Getting tokens...')
        const { access_token: respAccessToken, id_token: respIdToken } = response
        if (respAccessToken && respIdToken) {
          localStorage.setItem(ACCESS_TOKEN_KEY, respAccessToken)
          setAccessToken(respAccessToken)
          localStorage.setItem(ID_TOKEN_KEY, respIdToken)
          setIdToken(respIdToken)
          logger.log('Tokens recieved')
        } else {
          logger.error('Unexpected signinRedirectCallback response')
          setAuthError(new Error('Could not login - something went wrong.'))
        }
        setRedirect(true)
      })
        .catch((error) => {
          setAuthError(error)
        })
    }

    // Handle logout case
    if (location.pathname === '/post-logout-callback') {
      logger.log('Logout callback processed')
      setRedirect(true)
    }
  }, [location])

  // Context Methods
  const login = () => {
    // Save path so we can redirect to it after we process the callback url
    localStorage.setItem(REDIRECT_PATH_KEY, location.pathname)
    UserManager.signinRedirect()
  }

  const logout = () => {
    // Save path so we can redirect to it after we process the callback url
    localStorage.setItem(REDIRECT_PATH_KEY, location.pathname)

    // Clean up tokens
    const token = idToken
    localStorage.removeItem(ACCESS_TOKEN_KEY)
    localStorage.removeItem(ID_TOKEN_KEY)
    setAccessToken(null)
    setIdToken(null)
    logger.log('Auth Tokens Removed')
    UserManager.signoutRedirect({ id_token_hint: token })
  }

  const redirectUrl = localStorage.getItem(REDIRECT_PATH_KEY) || '/'
  const children = _.get(props, 'children')

  // Provider Render
  return (
    <AuthContext.Provider value={{
      isAuthenticated: !!accessToken,
      authError,
      login,
      logout,
    }}
    >
      { redirect ? (
        <Redirect to={redirectUrl} />
      ) : null }
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.defaultProps = {
  children: null,
}

export default AuthProvider
