// @flow strict
import React from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'

import { routes } from 'utils'
import { FlexBox } from 'components'

const textClassName = 'font-sans font-semibold text-sm text-white text-opacity-50'

const Policies = () => (
  <>
    <FlexBox height="h-16 flex-col md:flex-row" justifyContent="justify-center md:justify-start">
      <div className={`pt-8 md:pt-0 pl-0 md:pl-8 lg:pl-18 ${textClassName}`}>
        &copy;
        {' '}
        {format(new Date(), 'yyyy')}
        {' '}
        US Air Force SBIR-SSTR. All Rights Reserved.
      </div>
      <FlexBox className="flex-1" justifyContent="justify-end">
        <Link to={routes.contact}>
          <div className={`pr-0 md:pr-8 lg:pr-18 ${textClassName}`}>Contact Us</div>
        </Link>
      </FlexBox>
    </FlexBox>
    <div className={`${textClassName} px-12 pb-12`}>
      The security accreditation level of this site is UNCLASSIFIED//FOUO and
      below. Do not process, store, or transmit information classified above the
      accreditation level of this system. It is the responsibility of all users
      to ensure information extracted from the Air Force SBIR/STTR site is
      appropriately marked and properly safeguarded. You are accessing a U.S.
      Government (USG) Information System (IS) that is provided for
      USG-authorized use only. By using this information system (which includes
      any device attached to this information system), you consent to the web
      policies of the system.
    </div>
  </>
)

export default Policies
