// @flow strict
import React from 'react'

const Breadcrumbs = ({ title }: {title: ?string}) => (
  <div className="p-4 ml-12">
    <a className="text-gray-600" href="/">Home / </a>
    <span>{title}</span>
  </div>
)

export default Breadcrumbs
