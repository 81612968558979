// @flow strict
import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from 'utils'
import { FlexBox } from 'components'

const linkHeaderClass = 'pt-8 md:pt-24 pl-18 md:pl-12 lg:pl-24 font-sans font-semibold text-base text-white text-opacity-70'
const linkClass = 'pl-18 md:pl-12 lg:pl-24 pt-5 font-sans font-semibold text-base text-white text-opacity-50'

const SiteLinks = () => (
  <FlexBox className="w-full md:w-1/2" alignItems="items-start" height="h-64 md:h-112" justifyContent="justify-start">
    <FlexBox className="flex-col" alignItems="items-start" justifyContent="justify-start">
      <div className={linkHeaderClass}>
        SITE NAV
      </div>
      <Link to={routes.about}>
        <div className={linkClass}>About</div>
      </Link>
      <Link to={routes.contact}>
        <div className={linkClass}>Contact Us</div>
      </Link>
    </FlexBox>
    <FlexBox className="flex-col" alignItems="items-start" justifyContent="justify-start">
      <div className={linkHeaderClass}>
        SBIR/STTR Program
      </div>
      <a href="https://www.afsbirsttr.af.mil/About/Program-Overview/">
        <div className={linkClass}>Program Overview</div>
      </a>
      <a href="https://www.afsbirsttr.af.mil/Events/Pitch-Days/">
        <div className={linkClass}>Pitch Days</div>
      </a>
      <a href="https://www.afsbirsttr.af.mil/FAQs/">
        <div className={linkClass}>FAQ</div>
      </a>
      <a href="https://www.afsbirsttr.af.mil/News/">
        <div className={linkClass}>News</div>
      </a>
      <a href="https://www.afsbirsttr.af.mil/Events/">
        <div className={linkClass}>Upcoming Events</div>
      </a>
    </FlexBox>
  </FlexBox>
)

export default SiteLinks
