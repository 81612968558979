// @flow strict
import React from 'react'

import {
  Categories, Hero, SecondaryHero, Solicitation,
} from './components'

const HomePresenter = () => (
  <div className="w-full bg-off-white">
    <Hero />
    <SecondaryHero />
    {/*
      *
      *** TODO: Statistics ***
      *
    */}
    <Categories />
    <Solicitation />
  </div>
)

export default HomePresenter
