// @flow strict
import React from 'react'

import type { SearchResultSet } from 'search'

type ResultListProps = {
  resultClicked: (contractNumber: string) => void,
  searchResult: SearchResultSet
}

const ResultList = (props: ResultListProps) => {
  const { resultClicked, searchResult } = props
  const { error, results, searchValue } = searchResult

  // Error results state
  if (error) {
    return (
      <div className="mt-8 mr-16 flex-col items-center">
        <div className="font-sans font-bold text-lg text-dark-grey">Search Request Failed</div>
        <div className="pt-4 text-red-500">{error.message}</div>
      </div>
    )
  }

  // Empty results state
  if (results.length === 0) {
    return (
      <div className="mt-8 mr-16 flex items-center">
        <div className="text-dark-grey">{`No results found for '${searchValue}'`}</div>
      </div>
    )
  }

  return (
    <div className="mt-8 mr-16 bg-white" style={{ boxShadow: '0 2px 16px 3px rgba(0, 0, 0, 0.09)' }}>
      {
        searchResult.results.map((result) => {
          const {
            id, name, slug, metafields: { agency, branch, contractNumber, phase },
          } = result

          let phaseMapping = ''
          switch (phase) {
            case 'Phase 1':
              phaseMapping = 'Phase I'
              break
            case 'Phase 2':
              phaseMapping = 'Phase II'
              break
            case 'Phase 3':
              phaseMapping = 'Phase III'
              break
            default:
              break
          }

          return (
            <div id="SearchResult" key={id}>
              <div
                className="h-24 pl-8 flex flex-col justify-center cursor-pointer"
                onClick={() => resultClicked(contractNumber)}
                onKeyUp={(event) => { if (event.key === 'Enter') resultClicked(contractNumber) }}
                role="menuitem"
                tabIndex="0"
              >
                <div className="font-sans font-semibold text-xs text-charcoal-grey uppercase">
                  { agency }
                  {' '}
                  |
                  { branch }
                  {' '}
                  |
                  { phaseMapping }
                </div>
                <div className="font-sans font-semibold text-lg text-dark-grey">
                  {name}
                </div>
              </div>
              <hr />
            </div>
          )
        })
      }
    </div>
  )
}

export default ResultList
