// @flow strict
import React from 'react'
import numeral from 'numeral'

type AwardPhaseProps = {
  phase: ?string,
  cost: ?string,
  date: ?string,
  grey?: boolean,
}

const AwardPhase = ({
  phase,
  cost,
  date,
  grey,
}: AwardPhaseProps) => {
  const color = grey ? 'gray-500' : 'dusk-blue'
  let dateFormatted
  if (date) {
    dateFormatted = date.replace(/\-/g, '/')
    // TODO, more robust date parsing
  }

  return (
    <div className="mt-4 flex space-x-2">
      <div className={`rounded-full h-2 w-2 m-1 bg-${color} border border-solid border-gray-100 z-10`} />
      <div className={`font-bold uppercase text-${color} text-center`}>{phase}</div>
      { cost && (
        <>
          <span>|</span>
          <div className="text-center">{numeral(parseFloat(cost)).format('$0a')}</div>
        </>
      )}
      { date && (
        <>
          <span>|</span>
          <div className="text-center ml-4">{dateFormatted}</div>
        </>
      )}
    </div>
  )
}

AwardPhase.defaultProps = {
  grey: false,
}

type AwardTimelineProps = {
  awardPhase: ?string,
  rdCost: ?string,
  awardStart: ?string,
}

const AwardTimeline = ({
  awardPhase,
  rdCost,
  awardStart,
}: AwardTimelineProps) => {
  const PHASES = ['Phase I', 'Phase II', 'Phase III']

  // $FlowFixMe
  return (
    <div className="relative">
      <div
        style={{
          left: 7.5,
          height: 'calc(100% - 1.2em)', // match m-2 value
        }}
        className="absolute w-px bg-gray-500 my-2 z-0"
      />
      { PHASES.map((phase: string) => {
        const currentPhase = (awardPhase === phase)
        return (
          <>
            { currentPhase
              ? <AwardPhase phase={phase} cost={rdCost} date={awardStart} />
              : <AwardPhase phase={phase} cost="" date="" grey />}
          </>
        )
      })}
    </div>
  )
}

export default AwardTimeline
