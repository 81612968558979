// @flow strict
import * as React from 'react'
import { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { useSearch } from '../../search/SearchProvider'

const SortSelect = () => {
  const [show, setShow] = useState(false)
  const {
    currentSort,
    setCurrentSort,
  } = useSearch()

  const SORT_OPTIONS = [
    // a list of possible sort options
    // each with display name, elasticsearch field keyword, and order (asc/desc)
    {
      name: 'Relevance',
      field: '_score',
      order: 'desc',
    },
    {
      name: 'Newest',
      field: 'metafields.award-year.keyword',
      order: 'desc',
    },
    {
      name: 'Oldest',
      field: 'metafields.award-year.keyword',
      order: 'asc',
    },
    {
      name: 'Cheapest',
      field: 'metafields.rdCost.keyword',
      order: 'asc',
    },
    {
      name: 'Expensive',
      field: 'metafields.rdCost.keyword',
      order: 'asc',
    },
  ]

  return (
    <div className="text-sm text-black cursor-pointer" onClick={() => setShow(!show)} role="presentation">
      Sort by
      {' '}
      <span className="uppercase">{currentSort.name}</span>
      {' '}
      <FontAwesomeIcon icon={show ? faChevronUp : faChevronDown} />
      <div className={`absolute bg-white shadow-outside ${show ? '' : 'hidden'}`}>
        <ul className="my-2 mx-4">
          { SORT_OPTIONS.map((opt, index) => (
            <li className="uppercase" key={opt.name} onClick={() => setCurrentSort(SORT_OPTIONS[index])}>{opt.name}</li>
          )) }
        </ul>
      </div>
    </div>
  )
}

export default SortSelect
