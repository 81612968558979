// @flow strict
import * as React from 'react'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { useSearch } from '../../search/SearchProvider'

type FilterProps = {
  heading: string,
  showDefault?: boolean,
  children: React.Node,
}

const Filter = (props: FilterProps) => {
  const {
    heading,
    showDefault,
    children,
  } = props

  const [show, setShow] = useState(showDefault)

  return (
    <div className="py-4">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className="flex flex-row cursor-pointer" onClick={() => setShow(!show)} role="presentation">
        <div className="inline-block font-bold uppercase text-dark-grey">
          {heading}
        </div>
        <div className="inline-block ml-auto">
          <FontAwesomeIcon icon={show ? faMinus : faPlus} />
        </div>
      </div>
      <div className={`mt-2 flex flex-col ${show ? '' : 'hidden'}`}>
        {children}
      </div>
    </div>
  )
}

Filter.defaultProps = {
  showDefault: false,
}

const LABEL_CLASSES = 'flex align-top mb-2'
const INPUT_CLASSES = 'form-input mt-1 mr-2'
const CHECKBOX_CLASSES = 'form-checkbox h-4 w-4 mt-1 mr-2'
const SELECT_CLASSES = 'form-select mt-1 mr-2'

const FilterList = () => {
  const { register, watch, reset } = useForm()
  const watchForm = watch()

  const {
    currentFilters,
    setCurrentFilters,
  } = useSearch()

  // watch form values and send up to API
  useEffect(() => {
    // check to see if watchForm got reset event
    if (watchForm.currentTarget && watchForm.currentTarget.type === 'reset') {
      setCurrentFilters({})
      return
    }

    // now comparison objects to see if it changed
    // this may not work if key order changes
    if (JSON.stringify(watchForm) !== JSON.stringify(currentFilters)) {
      setCurrentFilters(watchForm)
    }
  }, [watchForm, currentFilters, setCurrentFilters])

  return (
    <form className="flex flex-col divide-y divide-gray-400 divide-solid text-sm">

      <div className="flex flex-row w-full mb-2">
        <div className="font-bold">Filter by</div>
        <div className="ml-auto">
          {/* eslint-disable-next-line react/button-has-type */}
          <button className="text-xs text-bluish uppercase self-end cursor-pointer" onClick={reset} type="reset">clear all</button>
        </div>
      </div>

      <Filter heading="Phase" showDefault>
        <label className={LABEL_CLASSES}>
          <input name="phase" className={CHECKBOX_CLASSES} type="checkbox" value="Phase 1" ref={register} />
          <span>Phase I</span>
        </label>
        <label className={LABEL_CLASSES}>
          <input name="phase" className={CHECKBOX_CLASSES} type="checkbox" value="Phase 2" ref={register} />
          <span>Phase II</span>
        </label>
        <label className={LABEL_CLASSES}>
          <input name="phase" className={CHECKBOX_CLASSES} type="checkbox" value="Phase 3" ref={register} />
          <span>Phase III</span>
        </label>
      </Filter>

      <Filter heading="Award Year" showDefault>
        <div className="flex-row">
          <select name="award-year-min" className={SELECT_CLASSES} ref={register}>

            <option value="2000">2000</option>
            <option value="2001">2001</option>
            <option value="2002">2002</option>
            <option value="2003">2003</option>
            <option value="2004">2004</option>
            <option value="2005">2005</option>
            <option value="2006">2006</option>
            <option value="2007">2007</option>
            <option value="2008">2008</option>
            <option value="2009">2009</option>
            <option value="2010">2010</option>
            <option value="2011">2011</option>
            <option value="2012">2012</option>
            <option value="2013">2013</option>
            <option value="2014">2014</option>
            <option value="2015">2015</option>
            <option value="2016">2016</option>
            <option value="2017">2017</option>
            <option value="2018">2018</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
          </select>
          <span className="mr-2">&mdash;</span>
          <select name="award-year-max" className={SELECT_CLASSES} ref={register}>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
            <option value="2018">2018</option>
            <option value="2017">2017</option>
            <option value="2016">2016</option>
            <option value="2015">2015</option>
            <option value="2014">2014</option>
            <option value="2013">2013</option>
            <option value="2012">2012</option>
            <option value="2011">2011</option>
            <option value="2010">2010</option>
            <option value="2009">2009</option>
            <option value="2008">2008</option>
            <option value="2007">2007</option>
            <option value="2006">2006</option>
            <option value="2005">2005</option>
            <option value="2004">2004</option>
            <option value="2003">2003</option>
            <option value="2002">2002</option>
            <option value="2001">2001</option>
            <option value="2000">2000</option>
          </select>
        </div>
      </Filter>

      <Filter heading="Cohort">
        <select name="cohort" className={SELECT_CLASSES} ref={register}>
          <option value="">-</option>
          <option value="year-1">Year 1</option>
          <option value="year-2">Year 2</option>
          <option value="year-3">Year 3</option>
        </select>
      </Filter>

      <Filter heading="Cost">
        <div className="flex-row">
          <input name="rdCost-min" className={INPUT_CLASSES} placeholder="MIN" ref={register} />
          <span className="mr-2">&mdash;</span>
          <input name="rdCcost-max" className={INPUT_CLASSES} placeholder="MAX" ref={register} />
        </div>
      </Filter>

      <Filter heading="Type of Solution">
        <label className={LABEL_CLASSES}>
          <input name="solutionType" className={CHECKBOX_CLASSES} type="checkbox" value="hardware" ref={register} />
          <span>Hardware</span>
        </label>
        <label className={LABEL_CLASSES}>
          <input name="solutionType" className={CHECKBOX_CLASSES} type="checkbox" value="software" ref={register} />
          <span>Software</span>
        </label>
        <label className={LABEL_CLASSES}>
          <input name="solutionType" className={CHECKBOX_CLASSES} type="checkbox" value="hardware+software" ref={register} />
          <span>Hardware + Software</span>
        </label>
      </Filter>

      <Filter heading="Minority (FPO)">
        <label className={LABEL_CLASSES}>
          <input name="company-hubzoneOwned" className={CHECKBOX_CLASSES} type="checkbox" value="true" ref={register} />
          <span>HUBZone</span>
        </label>
        <label className={LABEL_CLASSES}>
          <input name="company-womanOwned" className={CHECKBOX_CLASSES} type="checkbox" value="true" ref={register} />
          <span>Women Owned</span>
        </label>
        <label className={LABEL_CLASSES}>
          <input name="company-sedStatus" className={CHECKBOX_CLASSES} type="checkbox" value="true" ref={register} />
          <span>Socially/Economically disadvantaged</span>
        </label>
        <label className={LABEL_CLASSES}>
          <input name="company-disabledVeteran" className={CHECKBOX_CLASSES} type="checkbox" value="true" ref={register} />
          <span>Service-disabled veteran-owned small business</span>
        </label>
      </Filter>
    </form>
  )
}

export default FilterList
