import * as React from 'react'
import _ from 'lodash'

type TextProps = {
  children?: React.Node,
}

const Text = (props: TextProps) => {
  const children = _.get(props, 'children')
  return (
    <p className="font-nav font-normal text-sm text-charcoal-grey">{children}</p>
  )
}

Text.defaultProps = {
  children: null,
}

export default Text
