import React from 'react'

const ProductPress = ({ press }: { press: ?string[] }) => (
  <>
    <div className="font-bold uppercase text-gray-800 mt-8">Press</div>
    <ul>
      { Array.isArray(press) && press.length > 0 ? press.map((link) => (
        <li className="my-4 text-blue-600 cursor-pointer" key={link}>
          <a href={link} target="_blank" rel="noreferrer">{link}</a>
        </li>
      )) : 'None'}
    </ul>
  </>
)

export default ProductPress
