import React from 'react'

const ProductSpecs = () => (
  <>
    <div className="flex flex-col mt-4">
      <div className="font-bold text-gray-800 uppercase">Tech Specs</div>
      <div className="flex flex-row mt-2">
        <span className="bg-yellow-200 rounded p-4">
          Log In to view full details.
          <a className="text-blue-600 cursor-pointer"> Log in </a>
          or
          <a className="text-blue-600 cursor-pointer"> register now </a>
        </span>
      </div>
    </div>
  </>
)

export default ProductSpecs
