// @flow strict
import React from 'react'

import { FlexBox } from 'components'

const AboutSBIR = () => (
  <FlexBox className="w-full md:w-1/2 flex-col" height="h-69 md:h-112" alignItems="items-start md:items-center" justifyContent="justify-start">
    <div className="w-104 pt-8 md:pt-24 pl-18 md:pl-0 font-sans font-semibold text-base text-white text-opacity-70">
      ABOUT US AIR FORCE SBIR-STTR
    </div>
    <div className="w-104 pt-6 pl-18 md:pl-0 font-sans font-semibold text-base text-white text-opacity-50">
      The Small Business Innovation Research (SBIR) program was established by
      Congress in 1982 to fund research and development (R&amp;D) by small
      businesses of 500 or fewer employees.
      <br />
      <br />
      The Small Business Technology Transfer (STTR) program was established in
      1992 to fund cooperative R&amp;D projects with small businesses and
      non-profit U.S. research institutions, such as universities.
    </div>
    <div className="w-104 pt-8 pl-18 md:pl-0">
      <button className="w-28 h-12 bg-bluish rounded font-sans font-semibold text-white text-sm" type="button">SIGN UP</button>
    </div>
  </FlexBox>
)

export default AboutSBIR
