import { gql } from '@apollo/client'

export const GET_VENDOR = gql`
  query getVendor($name:String!) {
    companies(where: {Name: $name}){
      id
      created_at
      updated_at
      Description
      Name
      Hubzone_Owned
      Socially_Economically_Disadvantaged
      Woman_Owned
      Number_Employees
      Company_Website
      Address1
      Address2
      City
      State
      Zip
      Logo {
        url
      }
      published_at
      sbirs {
        Title
        Contract
        Agency
        Branch
        Phase
      }
      Images {
        url
      }
      Company_Phone
      Business_Contact_Name
      Business_Contact_Phone
      Business_Contact_Email
      Start_Year
    }
  }
`
