import React from 'react'
import ReactPlayer from 'react-player'

const ProductVideo = ({ videoUrl }: { videoUrl: string }) => (
  <>
    <div className="mt-8 w-3/5 h-104">
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="100%"
      />
    </div>
  </>
)

export default ProductVideo
