import React, { useState } from 'react'

const images = {
  'FA8650-19-P-1863': [
    '/POC1.png',
    '/POC2.png',
    '/POC3.png',
  ],
  'FA8501-18-P-0055': [
    '/ES3-1.jpg',
    '/ES3-2.jpg',
    '/ES3-3.jpg',
  ],
  'FA9453-19-P-0559': [
    '/ALE-1.jpg',
    '/ALE-2.jpg',
  ],
  default: [
    'https://via.placeholder.com/280',
    'https://via.placeholder.com/280',
    'https://via.placeholder.com/280',
  ],
}

const ProductHeaderImages = ({ contractNumber = 'default', imageUrls }: { contractNumber: ?string, imageUrls: ?string[] }) => {
  let productImages = imageUrls

  if (!productImages || productImages.length === 0) {
    productImages = images.default
  }

  const [mainImage, setMainImage] = useState(productImages[0])

  return (
    <div>
      <div>
        <img src={mainImage} className="w-full pb-2/3" />
      </div>
      <div className="flex flex-row justify-start mt-4">
        {productImages.map((img) => (
          <div key={img}>
            <img src={img} onClick={() => setMainImage(img)} className="h-16 w-16 cursor-pointer mr-4" />
          </div>
        ))}
      </div>

    </div>
  )
}

export default ProductHeaderImages
