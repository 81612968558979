import React from 'react'

const technologies = [
  {
    preface: 'Defense | Air Force',
    title: 'Modulated single-laser atom inferometer produc...',
    body: '',
  },
  {
    preface: 'Defense | Air Force',
    title: 'Broad area quantim cascade laser with fund...',
    body: '',
  },
  {
    preface: 'Defense | Air Force',
    title: 'Laser Diode End Pumped Monoblock Laser',
    body: '',
  },
]

const SimilarTechnologies = () => (
  <div className="flex flex-col mt-8">
    <p className="w-full ml-4 font-bold uppercase text-gray-800">Similar Technologies</p>
    <div className="flex flex-row">
      {technologies.map((technology) => (
        <div className="shadow-outside w-1/3 m-4 p-8 rounded" key={technology.title}>
          <div className="mt-4 mb-4">
            <div className="text-gray-600">
              {technology.preface}
              <i className="fa fa-heart-o" />
            </div>
            <h3 className="text-lg text-gray-800">{technology.title}</h3>
          </div>
          <div className="leading-7">{technology.body.slice(0, 120)}</div>
        </div>
      ))}
    </div>
  </div>
)

export default SimilarTechnologies
