import React from 'react'

import StaticLayout from './Layout'

const About = () => (
  <StaticLayout
    title="About"
    sidebar={(
      <div>
        <h3 className="font-bold text-dark-grey text-lg">Lorem ipsum</h3>
        <p className="mt-4 font-semibold text-dark-grey text-xs leading-5">
          Cras at felis id eros mattis tempor. Interdum et malesuada fames ac ante ipsum
          primis in faucibus. Interdum et malesuada fames ac ante ipsum primis in faucibus.
        </p>
        <button
          className="mt-8 border border-solid border-bluish rounded py-2 w-full
            font-semibold text-bluish uppercase"
          type="button"
        >
          CTA
        </button>
      </div>
    )}
  >
    <h1 className="font-title text-4xl text-dusk-blue mb-4">About</h1>

    <h2 className="text-xl font-bold">
      Program Overview
    </h2>
    <p className="my-4">
      The Small Business Innovation Research (SBIR) program was established by Congress in 1982 to
      fund research and development (R&D) by small businesses of 500 or fewer employees. Eleven
      federal agencies participate in the program, including the Department of Defense.
    </p>
    <p className="my-4">
      The Small Business Technology Transfer (STTR) program was established in 1992 to fund
      cooperative R&D projects with small businesses and non-profit U.S. research institutions,
      such as universities. Five federal agencies participate, including the Department of Defense.
      Both programs focus on projects and services with the potential to develop into a product for
      military or commercial sectors.

    </p>
    <p className="my-4">
      View the SBIR/STTR overview video
      {' '}
      <a className="text-bluish" href="https://www.dvidshub.net/video/embed/629524">here</a>
    </p>

    <h2 className="text-xl font-bold mt-12">
      Eligibility Requirements
    </h2>
    <h3 className="font-bold mt-4">SBIR</h3>
    <ul className="list-disc pl-10">
      <li>Applicant is for-profit business based in the United States</li>
      <li>500 or fewer employees</li>
      <li>
        Principal investigator&#39;s primary employment (more than 50 percent) must be with small
        business
      </li>
      <li>Additional requirements apply</li>
    </ul>

    <h3 className="font-bold mt-4">STTR</h3>
    <ul className="list-disc pl-10">
      <li>Applicant is for-profit business based in the United States</li>
      <li>Partner with U.S. research institution</li>
      <li>Formal cooperative research and development effort</li>
      <li>
        At least 40% of work performed by small business while 30% of work performed by research
        institution
      </li>
      <li>Additional requirements apply</li>
    </ul>

    <h2 className="text-xl font-bold mt-12">
      Proposal Submissions
    </h2>
    <p className="my-4">
      A SBIR or STTR Phase I or Phase II proposal for any DoD component must be submitted through
      the online DoD Submission website at:
      {' '}
      <a className="text-bluish" href="https://www.dodsbirsttr.mil/submissions">
        https://www.dodsbirsttr.mil/submissions
      </a>
    </p>
  </StaticLayout>
)

export default About
