// @flow strict
import React from 'react'

import robotBackground from '../assets/robot.jpg'

const Solicitation = () => (
  <div className="w-full h-121 bg-bluish">
    <div className="absolute flex flex-col justify-center items-center w-full h-121 z-10">
      <div
        className="font-title text-center text-white text-4xl"
        style={{ textShadow: '0 2px 4px rgba(0, 0, 0, 0.35)' }}
      >
        SBIR/STTR Open Solicitation
      </div>
      <div
        className="pt-4 px-6 max-w-3xl font-sans text-center text-white text-base whitespace-pre-line"
        style={{ textShadow: '0 2px 4px rgba(0, 0, 0, 0.35)' }}
      >
        {'SBIR Topic XX is open and accepting proposals until Month Day, Year.\n\n'
        + 'STTR Topic XX is open and accepting proposals until Month Day, Year.\n\n'
        + 'Proposals must be submitted through DoD SBIR/STTR Innovation Portal (DSIP) '
        + 'Contact the Air Force SBIR/STTR Help Desk at 800-222-0336 or by email '
        + 'at usaf.team@afsbirsttr.us with questions.'}
      </div>
      <div className="h-12" />
      <a href="https://www.dodsbirsttr.mil">
        <button
          className="w-69 h-15 border rounded font-sans text-white text-base"
          type="button"
        >
          PROPOSAL SUBMISSION EHB
        </button>
      </a>
    </div>
    <img
      alt="robot-background"
      className="h-full w-full object-cover opacity-60"
      src={robotBackground}
    />
  </div>
)

export default Solicitation
