import * as React from 'react'

import Breadcrumbs from '../components/Breadcrumbs'

type StaticPageProps = {
  title: string,
  children: React.Node,
  sidebar?: React.Node,
}

const StaticPage = (props: StaticPageProps) => {
  const { title, sidebar, children } = props

  return (
    <div
      className="w-full bg-off-white"
    >
      <Breadcrumbs
        title={title}
      />
      <div className="w-full bg-off-white flex">
        <div className="w-3/4">
          <div className="ml-16 mb-16 bg-white">
            <div className="shadow-outside w-full p-8 rounded">
              {children}
            </div>
          </div>
        </div>
        { sidebar && (
        <div className="w-1/4">
          <div className="mx-10">
            {sidebar}
          </div>
        </div>
        )}
      </div>
    </div>
  )
}

StaticPage.defaultProps = {
  sidebar: false,
}

export default StaticPage
