import React from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexBox } from 'components'

type SocialLinkProps = {
  icon: any,
  link: string,
}

const SocialLink = (props: SocialLinkProps) => {
  const icon = _.get(props, 'icon')
  const link = _.get(props, 'link')

  return (
    <FlexBox className="w-20 sm:w-25 h-full border border-white border-opacity-1">
      <a href={link}>
        <FontAwesomeIcon className="w-full text-white text-3xl" icon={icon} />
      </a>
    </FlexBox>
  )
}

export default SocialLink
