import React from 'react'

import ProductHeaderImages from './ProductHeaderImages'
import ProductHeaderDetails from './ProductHeaderDetails'
import ProductHeaderAbstract from './ProductHeaderAbstract'

type ProductHeaderProps = {
  agency: ?string,
  branch: ?string,
  contractNumber: ?string,
  description: ?string,
  phase: ?string,
  program: ?string,
  tags: string[],
  title: ?string,
  imageUrls: ?string[],
}

const ProductHeader = ({
  agency, branch, contractNumber, description, phase, program, tags, title, imageUrls,
}: ProductHeaderProps) => (
  <>
    <div className="flex flex-row">
      <div className="w-1/4">
        <ProductHeaderImages
          contractNumber={contractNumber}
          imageUrls={imageUrls}
        />
      </div>
      <div className="w-3/4 pl-8">
        <ProductHeaderDetails
          agency={agency}
          branch={branch}
          phase={phase}
          program={program}
          tags={tags}
          title={title}
        />
        <ProductHeaderAbstract
          description={description}
        />
      </div>
    </div>
  </>
)

export default ProductHeader
