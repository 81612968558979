import React from 'react'

const ProductContact = () => (
  <>
    <div className="flex flex-row justify-center my-8">
      <a className="bg-blue-600 text-white rounded p-4 px-8 uppercase mr-2">Request SBIR</a>
      <a className="rounded border-solid border-blue-600 border-2 text-blue-600 uppercase p-4 px-8 ml-2">Ask Question</a>
    </div>
  </>
)

export default ProductContact
