import React from 'react'
import { Link } from 'react-router-dom'

import flaskIcon from 'assets/flask.svg'

type CategoryProps = {
  name: String,
  count: Number,
}

const Category = ({ name, count }: CategoryProps) => {
  const link = `/products?category=${encodeURIComponent(name)}`

  return (
    <div className="flex flex-row flex-shrink w-1/4 border shadow rounded-md mx-2 my-2">
      <div className="bg-gray-400 flex items-center p-4">
        <img src={flaskIcon} className="h-16 w-16 p-2 object-center max-w-none" alt="Flask Icon" />
      </div>
      <div className="flex flex-col p-4 cursor-pointer">
        <p className="font-bold"><Link to={link}>{name}</Link></p>
        <p className="text-dusk-blue">{count}</p>
      </div>
    </div>
  )
}

export default Category
