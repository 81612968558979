import React from 'react'

import StaticLayout from './Layout'

const ContactUs = () => (
  <StaticLayout title="Contact">
    <h1 className="font-title text-4xl text-dusk-blue">Contact Us</h1>

    <p className="my-4">
      Phone: 855-855-5360
      <br />
      Email: usaf.team@afsbirsttr.us
    </p>

    <p>
      All calls and emails will go to contractor HPC COM, then fielded and distributed from there.
      Many questions will be answered with common information or “validated answers” and more
      technical, detailed questions and requests will be sent to the Center of Excellence Branch
      Leads for response.
    </p>
  </StaticLayout>
)

export default ContactUs
