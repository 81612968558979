// @flow strict
import React from 'react'
import { Link } from 'react-router-dom'

export type VendorProductProps = {
  agency: ?string,
  contract: String,
  branch: ?string,
  phase: ?string,
  title: ?string,
}

export const VendorProduct = ({
  agency,
  branch,
  contract,
  phase,
  title,
}: VendorProductProps) => (
  <Link to={`/product/${contract}`}>
    <div className="p-6">
      <div className="uppercase text-gray-900 text-sm">
        {agency}
        {' '}
        |
        {' '}
        {branch}
        {' '}
        |
        {' '}
        {phase}
      </div>
      <div className="text-lg">{ title }</div>
      </div>
    </Link>
)
