import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { routes } from 'utils'

import { About, ContactUs, NotFound } from 'static-page'

import Footer from 'footer'
import Home from 'home'
import ProductCategoryPage from 'product-category-page'
import ProductDetailPage from 'product-detail-page'
import ProductListingPage from 'product-listing-page'
import VendorPage from 'vendor-page'
import NavBar from 'nav-bar'
import { SearchProvider } from 'search'

const App = () => (
  <SearchProvider>
    <NavBar />
    <div className="h-20" />
    <Switch>
      <Route exact path={routes.about}>
        <About />
      </Route>
      <Route exact path={routes.contact}>
        <ContactUs />
      </Route>
      <Route path="/products">
        <ProductListingPage />
      </Route>
      <Route path="/product/:contract">
        <ProductDetailPage />
      </Route>
      <Route path="/vendor/:slug">
        <VendorPage />
      </Route>
      <Route exact path={routes.home}>
        <Home />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
    <Footer />
  </SearchProvider>
)

export default App
