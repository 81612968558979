// @flow strict
import * as React from 'react'
import _ from 'lodash'

type FlexBoxProps = {
  alignItems?: string,
  children?: React.Node,
  className?: string,
  height?: string,
  justifyContent?: string,
}

const FlexBox = (props: FlexBoxProps) => {
  const alignItems = _.get(props, 'alignItems')
  const children = _.get(props, 'children')
  const additionalClasses = _.get(props, 'className')
  const height = _.get(props, 'height')
  const justifyContent = _.get(props, 'justifyContent')

  const flexClass = `flex ${height} ${alignItems} ${justifyContent} ${additionalClasses}`

  return (
    <div className={flexClass}>
      {children}
    </div>
  )
}

FlexBox.defaultProps = {
  alignItems: 'items-center',
  children: null,
  className: '',
  height: 'h-full',
  justifyContent: 'justify-center',
}

export default FlexBox
